import { useEffect, useState, lazy, Suspense, useTransition } from 'react';

import { useTheme } from '@mui/material/styles';
import { Box, Skeleton, useMediaQuery } from '@mui/material';
// import { Main } from '~/content/layouts/Main/Main';
import { Container } from '~/content/components/Container';
import Scroll from 'react-scroll';

import Hero from './hero.tsx';
import CaseStudy1 from './caseStudy1.tsx';
// const CaseStudy1 = lazy(() => import('./caseStudy1'));
// const CaseStudy2 = lazy(() => import('./caseStudy2'));
// const CaseStudy3 = lazy(() => import('./caseStudy3'));
// const Partners = lazy(() => import('./partners'));
// const AskExpert = lazy(() => import('./askExpert'));
const CaseStudy2 = lazy(async () => ({
	default: (await import('./caseStudy2')).CaseStudy2,
}));
const CaseStudy3 = lazy(async () => ({
	default: (await import('./caseStudy3')).CaseStudy3,
}));
const Partners = lazy(async () => ({
	default: (await import('./partners')).Partners,
}));
const AskExpert = lazy(async () => ({
	default: (await import('./askExpert')).AskExpert,
}));
const Contact = lazy(async () => ({
	default: (await import('./contact')).Contact,
}));

// const Contact = lazy(() => import('./contact'));
// import CaseStudy2 from './caseStudy2';
// import CaseStudy3 from './caseStudy3';
// import Partners from './partners';
// import AskExpert from './askExpert';
// import Contact from './contact';

import { trackPageview } from '~/utils/plausible';

const indexView = (): JSX.Element => {
	useEffect(() => {
		trackPageview();
	}, []);

	const theme = useTheme();
	const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});
	const isMd = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true,
	});

	useEffect(() => {
		const scroller = () => {
			Scroll.scroller.scrollTo('index_section_1', {
				duration: 1500,
				delay: 250,
				smooth: true,
				// containerId: 'ContainerElementID',
				// offset: 50 // Scrolls to element + 50 pixels down the page
			});
		};

		const urlParams = new URLSearchParams(window.location.search);
		// console.log(urlParams.get('scrollTo'));
		if (urlParams.get('scrollTo') === 'index_section_1') {
			scroller();
		}

		// window.addEventListener('load', () => {
		//   const urlParams = new URLSearchParams(window.location.search);
		//   console.log({ urlParams });
		// });
	}, []);

	const size = '3px';
	const gap = '16px';
	return (
		<>
			<Box>
				{/* <Plausible /> */}
				{/* <Main bgcolor={'background.paper'}> */}
				<Box
					bgcolor={'alternate.main'}
					sx={{
						position: 'relative',
						'&::after': {
							position: 'absolute',
							content: '""',
							width: isMd ? '37%' : '0%',

							// width: '37%',
							zIndex: 1,
							top: 0,
							left: '5%',
							height: '96%',
							inset: gap,
							WebkitMask: `
									conic-gradient(at ${size} ${size},#0000 75%,#000 0) 0 0,
									conic-gradient(at ${size} ${size},#0000 75%,#000 0) 0 0;
									`,
							WebkitMaskSize: `calc(${size} + ${gap}) calc(${size} + ${gap})`,
							background: theme.palette.primary.main,
							opacity: 0.1,
						},
					}}
				>
					{/* //* changed margin bottom (mb) */}
					<Box position={'relative'} zIndex={3} sx={{ mb: { xs: 4, sm: 10 } }}>
						<Hero />
					</Box>
				</Box>

				<Box>
					<Container>
						<Box position={'relative'}>
							<Scroll.Element
								name='index_section_1'
								style={{
									position: 'absolute',
									// top: isMd ? -60 : -75
									top: -190,
								}}
							/>
						</Box>
						<CaseStudy1 />
					</Container>
					<Suspense fallback={null}>
						<Container>
							{/* <Suspense fallback={null}> */}
							<CaseStudy2 />
							{/* </Suspense> */}
						</Container>

						<Container>
							{/* <Suspense fallback={null}> */}
							<CaseStudy3 />
							{/* </Suspense> */}
						</Container>
					</Suspense>
				</Box>
				<Suspense fallback={null}>
					<Box
						bgcolor={'secondary.dark'}
						position={'relative'}
						zIndex={3}
						sx={{ mb: { xs: 5, sm: 6 }, mt: { xs: 5, sm: 6 } }}
					>
						<Container paddingX={'0 !important'} maxWidth={1}>
							{/* <Suspense fallback={null}> */}
							<Partners />
							{/* </Suspense> */}
						</Container>
					</Box>
					<Box bgcolor={'alternate.main'} sx={{ mb: { xs: 3, sm: 3 }, mt: { xs: 9, sm: 15 } }}>
						<Container>
							{/* <Suspense fallback={null}> */}
							<AskExpert />
							{/* </Suspense> */}
						</Container>
					</Box>
					<Box sx={{ mb: { xs: 2, sm: 3 }, mt: { xs: 0, sm: 7 } }}>
						<Container>
							{/* <Suspense fallback={null}> */}
							<Contact />
							{/* </Suspense> */}
						</Container>
					</Box>
				</Suspense>
			</Box>
			<script
				type='application/ld+json'
				dangerouslySetInnerHTML={{
					__html: `
					{
						"@context": "https://schema.org",
						"@type": "Corporation",
						"@id": "https://northrock.software", 
						"name": "NorthRock software GmbH",
						"alternateName": "NorthRock software",
						"url": "https://northrock.software",
						"logo": "https://northrock.software/meta/thumbnailSquare.png",
						"founder": [
							{
							  "@type": "Person",
							  "givenName": "Leon",
							  "familyName": "Bernard",
							  "jobTitle": {
								"@type": "DefinedTerm",
								"name": "Chief Executive Officer"
							 }
							},
							{
							  "@type": "Person",
							  "givenName": "Daniel",
							  "familyName": "Partschev",
							  "jobTitle": {
								"@type": "DefinedTerm",
								"name": "Chief Technology Officer"
							}
							}
						  ],
						"address": {
							"@type": "PostalAddress",
							"addressCountry": "DE",
							"addressLocality": "Frankfurt am Main",
							"addressRegion": "Hessen",
							"postalCode": "60314",
							"streetAddress": "Hanauer Landstraße 146"
						  },
						"contactPoint": {
							"@type": "ContactPoint",
							"email": "info@northrock.software",
							"telephone": "+491731719939",
							"contactType": "sales",
							"availableLanguage": ["German","English"]
						},
						"sameAs": 
						["https://www.linkedin.com/company/northrock-software/",
						"https://twitter.com/NorthRock_soft",
						"https://github.com/NorthRock-software"
						]
					}
					`,
				}}
			/>
		</>
	);
};

export { indexView };
